main {
  min-height: 80vh;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h4 {
  color: #fff;
}

.carousel img {
  display: block;
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
.fully-centered {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.fully-centered-child {
  position: relative; /* or absolute */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

input[type="file"] {
  display: none;
}

@media all and (max-width: 800px), (min-width: 2000px) {
  .card-col {
    margin-left: "2rem" !important;
  }
}

.info p,
.info h5 {
  display: inline;
}

.check {
  background-color: #f5f5f5;
  border: 1px solid;
  border-radius: 5px;

  height: 30px;

  position: relative;

  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.check label {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  cursor: pointer;
  font-family: "sans";
}
.check input {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translateY(-70%);
  cursor: pointer;
}
input[type="checkbox"].checkbox {
  visibility: hidden;
}
.custom-box {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 3px;
}

